import {
  Textarea as ChakraTextArea,
  Flex,
  Text,
  TextareaProps,
} from '@chakra-ui/react';
import { ForwardedRef, forwardRef } from 'react';

interface TextareaInput extends TextareaProps {
  error?: string;
}

export const TextareaInput = forwardRef(
  (
    { error, ...props }: TextareaInput,
    ref: ForwardedRef<HTMLTextAreaElement>
  ) => {
    return (
      <Flex direction={'column'} w={'full'}>
        <ChakraTextArea
          ref={ref}
          borderWidth={error ? '2px' : '1px'}
          borderColor={error ? 'red.500' : 'gray.300'}
          borderRadius="md"
          _focus={{
            borderColor: 'primary',
            ring: '2px',
            ringColor: 'primary',
          }}
          {...props}
        />
        <Text color={'red.500'} size={'sm'}>
          {error}
        </Text>
      </Flex>
    );
  }
);

TextareaInput.displayName = 'TextareaInput';
