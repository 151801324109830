import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { BrowserRouter } from 'react-router-dom';

import moment from 'moment';
import 'moment/dist/locale/id';
import { OpenAPI } from './api';
import { AuthProvider } from './context/AuthContext';
import { MenuProvider } from './context/MenuContext';
import theme from './theme';

moment.locale('id');

OpenAPI.TOKEN = async () => {
  const token = Cookies.get('token');
  return token || '';
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

interface ProviderProps {
  children: React.ReactNode;
}

export default function Provider({ children }: ProviderProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <AuthProvider>
            <MenuProvider>{children}</MenuProvider>
          </AuthProvider>
        </BrowserRouter>
      </ChakraProvider>
    </QueryClientProvider>
  );
}
