import { Input as ChakraInput, Flex, InputProps, Text } from '@chakra-ui/react';
import { ForwardedRef, forwardRef } from 'react';

interface TextInputProps extends InputProps {
  error?: string;
  addon?: {
    position: 'right' | 'left';
  };
}

export const TextInput = forwardRef(
  (
    { error, ...props }: TextInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <Flex direction={'column'} w={'full'}>
        <ChakraInput
          ref={ref}
          borderWidth={error ? '2px' : '1px'}
          borderColor={error ? 'red.500' : 'gray.300'}
          borderLeftRadius={props.addon?.position === 'left' ? 'none' : 'md'}
          borderRightRadius={props.addon?.position === 'right' ? 'none' : 'md'}
          _focus={{
            borderColor: 'primary',
            ring: '2px',
            ringColor: 'primary',
          }}
          {...props}
        />
        <Text color={'red.500'} size={'sm'}>
          {error}
        </Text>
      </Flex>
    );
  }
);

TextInput.displayName = 'TextInput';
