import { extendTheme } from '@chakra-ui/react';
import { withProse } from '@nikolovlazar/chakra-ui-prose';

export default extendTheme(
  {
    fonts: {
      heading: 'Poppins',
      body: 'Poppins',
    },
    colors: {
      table: {
        100: '#FAFAFA',
      },
      orange: {
        1000: '#FD6323',
      },
      red: {
        1000: '#CA2424',
      },
      green: {
        1000: '#6DCA24',
      },
      yellow: {
        1000: '#FFB704',
      },
      blue: {
        1000: '#0D5FEE',
      },
    },
    components: {
      Table: {
        variants: {
          'data-table': {
            table: {
              // rounded
              borderStyle: 'hidden',
              borderRadius: '3xl',
            },
            th: {
              fontSize: 14,
              bgColor: 'blackAlpha.300',
              color: 'gray.600',
              textTransform: 'Capitalize',
              borderRight: '1px solid #A0AEC0 !important',
            },
            td: {
              borderRight: '1px solid #A0AEC0 !important',
            },
            caption: {},
            tbody: {
              tr: {
                '&:nth-of-type(even)': {
                  'th, td': {
                    borderBottomWidth: '1px',
                    background: 'blackAlpha.100',
                  },
                },
                td: {},
              },
            },
            tfoot: {
              tr: {
                '&:last-of-type': {
                  th: { borderBottomWidth: 0 },
                },
              },
            },
          },
        },
      },
      Button: {
        variants: {
          'erp-primary': {
            bg: '#005FEE',
            borderRadius: '3xl',
            color: '#FFF',
            _hover: {
              bg: '#FD6323',
              _disabled: {
                bg: '#C1C1C1',
              },
              _loading: {
                bg: '#FD6323',
              },
            },
            _disabled: {
              bg: '#C1C1C1',
              opacity: '1',
            },
            _loading: {
              bg: '#FD6323',
              opacity: '1',
            },
            _focus: {
              boxShadow: 'none',
            },
          },
          'erp-secondary': {
            bg: '#FFF',
            borderRadius: '3xl',
            border: 'solid 2px #005FEE',
            color: '#005FEE',
            _hover: {
              bg: '#005FEE',
              color: '#FFF',
              _disabled: {
                borderColor: '#C1C1C1',
                color: '#C1C1C1',
              },
              _loading: {
                bg: '#005FEE',
                borderColor: '#005FEE',
              },
            },
            _disabled: {
              borderColor: '#C1C1C1',
              color: '#C1C1C1',
              opacity: '1',
            },
            _loading: {
              bg: '#005FEE',
              borderColor: '#005FEE',
              opacity: '1',
            },
            _focus: {
              boxShadow: 'none',
            },
          },
        },
      },
    },
  },
  withProse()
);
