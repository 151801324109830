import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Link,
  Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { FaCheck, FaTimes } from 'react-icons/fa';

import { LandingPageService, ListService } from '@/api';

import Footer from '../components/footer';
import Navigation from '../components/navigation';

export default function Paket() {
  // const navigate = useNavigate();
  const { data: pakets } = useQuery(['pakets'], () =>
    LandingPageService.getPilihanPaket()
  );

  const { data: listFitur } = useQuery(['listFitur'], () =>
    ListService.getAllFitur()
  );

  return (
    <>
      <Navigation />
      <Box
        data-testid="content"
        py={12}
        width={{
          base: 'full',
          md: '90vw',
        }}
        mx={'auto'}
      >
        <Flex direction={'column'} gap={12}>
          <Heading
            fontWeight="semibold"
            color="gray.800"
            fontSize={'5xl'}
            textAlign={'center'}
            py={12}
          >
            Pilih Paket Yang Sesuai Dengan Kebutuhan
          </Heading>

          <Grid
            minWidth={'container.lg'}
            templateColumns={`18rem repeat(${pakets?.length}, 1fr)`}
            columnGap={3}
          >
            <GridItem h={10}></GridItem>
            {pakets?.map((paket) => (
              <GridItem
                key={paket.id_paket}
                mx={2}
                borderTop={'1px solid'}
                borderX={'1px solid'}
                borderColor={'gray.300'}
                borderRadius={'0.375rem 0.375rem 0 0'}
                py={4}
              >
                <Flex direction={'column'} gap={4} px={4}>
                  <Flex direction={'column'} alignItems={'center'}>
                    <Text
                      fontSize={'xl'}
                      fontWeight={'semibold'}
                      textAlign={'center'}
                      m={0}
                    >
                      {paket.nama_paket}
                    </Text>
                    <Text color={'gray.600'}>{paket.deskripsi_paket}</Text>
                  </Flex>
                  <Flex
                    direction={'column'}
                    placeItems={'center'}
                    placeContent={'center'}
                  >
                    <Text fontSize={'4xl'} fontWeight={'semibold'}>
                      {/* convert to rupiah */}
                      {new Intl.NumberFormat('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                      }).format(parseInt(paket.harga_perbulan))}
                    </Text>
                    <Text fontSize={'sm'} color={'gray.600'} my={-2}>
                      user / bulan
                    </Text>
                  </Flex>

                  <Text
                    textAlign={'center'}
                    fontSize={'lg'}
                    fontWeight={'medium'}
                    color={'gray.600'}
                    pt={2}
                    mb={-3}
                  >
                    atau
                  </Text>
                  <Flex
                    direction={'column'}
                    placeItems={'center'}
                    placeContent={'center'}
                  >
                    <Text fontSize={'4xl'} fontWeight={'semibold'}>
                      {/* convert to rupiah */}
                      {new Intl.NumberFormat('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                      }).format(parseInt(paket.harga_pertahun))}
                    </Text>
                    <Text fontSize={'sm'} color={'gray.600'} my={-2}>
                      user / tahun
                    </Text>
                  </Flex>
                  <Text
                    textAlign={'center'}
                    fontWeight={'semibold'}
                    color={'gray.600'}
                    pt={4}
                  >
                    Minimal {parseInt(paket.min_user)} user
                  </Text>
                  <Link
                    href={`paket/${paket.id_paket}`}
                    variant={'no-underline'}
                    w={'full'}
                    mx={'auto'}
                    my={3}
                  >
                    <Button variant={'erp-primary'} w={'full'}>
                      Berlangganan
                    </Button>
                  </Link>
                </Flex>
              </GridItem>
            ))}

            {listFitur?.map((fitur) => (
              <>
                <GridItem key={fitur.id_fitur}>
                  <Text
                    px={2}
                    py={3}
                    borderBottom={'1px solid'}
                    borderBottomColor={'gray.300'}
                    fontWeight={'medium'}
                  >
                    {fitur.nama_fitur}
                  </Text>
                </GridItem>
                {pakets?.map((paket) => (
                  <GridItem key={paket.id_paket}>
                    <Box mx={2} borderX={'1px solid'} borderColor={'gray.300'}>
                      <Flex
                        placeContent={'center'}
                        py={5}
                        pb={3}
                        mx={3}
                        borderBottom={'1px solid'}
                        borderBottomColor={'gray.300'}
                      >
                        {paket.fitur.find(
                          (f) => f.id_fitur === fitur.id_fitur
                        ) ? (
                          <Text color={'green.500'}>
                            <FaCheck />
                          </Text>
                        ) : (
                          <Text color={'gray.500'}>
                            <FaTimes />
                          </Text>
                        )}
                      </Flex>
                    </Box>
                  </GridItem>
                ))}
              </>
            ))}
            <GridItem h={10}></GridItem>
            {pakets?.map(({ id_paket }) => (
              <GridItem
                key={`footer-${id_paket}`}
                mx={2}
                borderBottom={'1px solid'}
                borderX={'1px solid'}
                borderColor={'gray.300'}
                borderRadius={'0 0 0.375rem 0.375rem'}
              ></GridItem>
            ))}
          </Grid>
        </Flex>
      </Box>
      <Footer />

    </>
  );
}
