import {
  Box,
  BoxProps,
  FormControl,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import id from 'date-fns/locale/id';
import { Ref, forwardRef, useState } from 'react';
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import { FaCalendarAlt } from 'react-icons/fa';

import { TextInput } from './text-input';
registerLocale('id', id);
type ReactDatePickerPropsWithoutOnChangeAndValue = Omit<
  ReactDatePickerProps,
  'onChange' | 'value'
>;

interface DatePickerProps extends ReactDatePickerPropsWithoutOnChangeAndValue {
  onChange?: (date: Date) => void;
  boxProps?: BoxProps;
  value?: Date;
  error?: string;
}

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  function DatePicker(
    { onChange, value, error, boxProps, ...props }: DatePickerProps,
    ref
  ) {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <Box {...boxProps}>
        <ReactDatePicker
          placeholderText="Pilih tanggal"
          locale={id}
          onChange={(date: Date) => onChange && onChange(date)}
          dateFormat="dd MMMM yyyy"
          selected={value}
          fixedHeight={true}
          showYearDropdown={true}
          onCalendarOpen={() => setIsOpen(true)}
          onCalendarClose={() => setIsOpen(false)}
          customInput={
            <Input
              error={error}
              ref={ref}
              isCalendarOpen={isOpen}
              isDisabled={props.disabled}
            />
          }
          {...props}
        ></ReactDatePicker>
      </Box>
    );
  }
);

interface InputProps {
  error?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isCalendarOpen?: boolean;
  isInvalid?: boolean;
  onClick?: () => void | ((date: Date) => void);
  value?: string;
}

const Input = forwardRef(
  (
    {
      isRequired,
      isInvalid,
      isDisabled,
      isCalendarOpen,
      error,
      onClick,
      value,
      ...props
    }: InputProps,
    ref: Ref<HTMLDivElement>
  ) => (
    <FormControl
      ref={ref}
      w={'full'}
      minW={240}
      bg={'transparent'}
      isDisabled={isDisabled ?? false}
      isInvalid={isInvalid ?? false}
      isRequired={isRequired ?? false}
      onClick={onClick}
    >
      <InputGroup placeContent={'space-between'} ring={0} rounded={'none'}>
        <TextInput
          error={error}
          ring={0}
          value={value}
          isReadOnly
          {...props}
        ></TextInput>

        <InputRightElement
          w={isCalendarOpen ? 43 : 41}
          color={isDisabled ? 'gray.500' : 'blue.1000'}
          borderColor={
            isCalendarOpen ? 'blue.1000' : error ? 'red.500' : 'gray.300'
          }
          borderLeftWidth={1}
          borderRightRadius="md"
          pointerEvents="auto"
          cursor={isDisabled ? 'not-allowed' : 'pointer'}
        >
          <FaCalendarAlt />
        </InputRightElement>
      </InputGroup>
    </FormControl>
  )
);

Input.displayName = 'Input';
