import axios from 'axios';
import Cookies from 'js-cookie';

const axiosConfig = {
  baseURL: `${import.meta.env.VITE_API_URL}/api/v1/`,
  timeout: 3000,
};

const axiosApiInstance = axios.create(axiosConfig);

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const token = Cookies.get('token');
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem('user-data');
      Cookies.remove('token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default axiosApiInstance;
