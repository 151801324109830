import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Icon,
  ModalBodyProps,
  ModalHeaderProps,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import { useCallback, useRef } from 'react';
import { IconType } from 'react-icons';

interface AlertHeaderProps extends ModalHeaderProps {
  children: React.ReactNode;
  icon?: IconType;
}

export const AlertHeader = ({ children, icon, ...props }: AlertHeaderProps) => {
  return (
    <AlertDialogHeader
      color="#FFF"
      bg="blue.1000"
      borderTopRadius="10px"
      padding="0px"
      paddingLeft="15px"
      minH="30px"
      fontSize="sm"
      display="flex"
      alignItems="center"
      {...props}
    >
      <Icon as={icon} size="15px" style={{ marginRight: '5px' }} />
      {children}
    </AlertDialogHeader>
  );
};

interface AlertBodyProps extends ModalBodyProps {
  children: React.ReactNode;
}

export const AlertBody = ({ children, ...props }: AlertBodyProps) => {
  return (
    <AlertDialogBody
      fontSize="sm"
      fontWeight="normal"
      p="unset"
      m={6}
      textAlign="center"
      {...props}
    >
      <Flex minH={32} h={'full'} placeItems="center">
        {children}
      </Flex>
    </AlertDialogBody>
  );
};

interface ButtonProps {
  label: string;
  onClick?: () => void;
}

interface AlertProps {
  disclosure: UseDisclosureReturn;
  isLoading: boolean;
  errorText?: string;
  children: React.ReactNode;
  button: ButtonProps;
  closeButtonText?: string;
}

export const AlertModal = ({
  disclosure,
  isLoading,
  button,
  closeButtonText,
  errorText,
  children,
}: AlertProps) => {
  const cancelRef = useRef<HTMLButtonElement>(null);

  const handleOnClose = useCallback(() => {
    if (!isLoading) {
      disclosure.onClose();
    }
  }, [isLoading, disclosure]);

  return (
    <AlertDialog
      isCentered
      isOpen={disclosure.isOpen}
      onClose={handleOnClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent borderRadius={10}>
          {children}
          <AlertDialogFooter
            flexDirection="column"
            alignItems="flex-end"
            p={'unset'}
            mx={4}
            mb={4}
          >
            <Box>
              {closeButtonText && (
                <Button
                  minW="7rem"
                  ref={cancelRef}
                  onClick={handleOnClose}
                  variant="erp-secondary"
                  size="sm"
                >
                  {closeButtonText}
                </Button>
              )}
              <Button
                ml={3}
                minW="7rem"
                variant="erp-primary"
                size="sm"
                isLoading={isLoading}
                onClick={() => {
                  button.onClick && button.onClick();
                }}
              >
                {button.label}
              </Button>
            </Box>
            {errorText && (
              <Text textColor="#CA2424" fontSize="sm" mt="2">
                {errorText !== undefined ? errorText : 'Gagal Menghapus Data.'}
              </Text>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
