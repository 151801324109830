window.global = globalThis;

import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useAuth } from './context/AuthContext';
import Login from './modules/auth/login';
import LoginCalonSiswa from './modules/auth/login-calon-siswa';
import Home from './modules/landing/pages/home';
import RegistrasiPaket from './modules/landing/pages/paket';
import RegistrasiSekolah from './modules/landing/pages/registrasi';
import KalkulasiPaket from './modules/registrasi-sekolah/pages/KalkulasiPaket';
import AktivasiUser from './pages/AktivasiUser';

const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const ForgotPassword = lazy(() => import('./pages/ForgetPassword'));
const AuthRouter = lazy(() => import('./auth-router'));

export default function Router() {
  const { user } = useAuth();
  const isAuthenticated = user?.loggedIn;

  return (
    <Routes>
      <Route path="/registrasi" element={<RegistrasiSekolah />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path={`/paket`} element={<RegistrasiPaket />}></Route>
      <Route path={`/paket/:id_paket`} element={<KalkulasiPaket />} />

      <Route path="/login/calon-siswa" element={<LoginCalonSiswa />}></Route>

      <Route path="/aktivasi/user/:token" element={<AktivasiUser />}></Route>

      <Route path="/reset-password/:token" element={<ResetPassword />}></Route>
      <Route path="/forgot-password" element={<ForgotPassword />}></Route>

      <Route
        path={`*`}
        Component={() => (isAuthenticated ? <AuthRouter /> : <Home />)}
      ></Route>
    </Routes>
  );
}
