import { Dispatch, useDebugValue, useEffect, useState } from 'react';

function useLocalStorage(key: string): [any, Dispatch<any>] {
  const serialize = (value: JSON) => JSON.stringify(value);
  const deserialize = (value: string) => JSON.parse(value);

  const [state, setState] = useState(() => {
    const value = window.localStorage.getItem(key);
    if (value) {
      try {
        return deserialize(value);
      } catch (e) {
        window.localStorage.removeItem(key);
      }
    }
  });
  useEffect(() => {
    window.localStorage.setItem(key, serialize(state));
  }, [state, key, serialize]);
  useDebugValue(`useLocalStorage ${state}`);
  return [state, setState];
}

export default useLocalStorage;
