import Cookies from 'js-cookie';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { useAuth } from '@/context/AuthContext';

export default function LoginCalonSiswa() {
  const { user, getUser } = useAuth();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const token = searchParams.get('token');

  const referer = searchParams.get('referer') || '/';

  if (token !== null) {
    console.log('token', token);
    setTimeout(() => {
      getUser.refetch();
    }, 1000);
    Cookies.set('token', token);
    Cookies.set('landing-page-sekolah', referer);
  } else {
    window.location.href = referer as string;
  }

  if (Cookies.get('token') && user) {
    return <Navigate to="/" />;
  }

  return <div></div>;
}
