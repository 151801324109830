import {
  Box,
  Button,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FaArrowRight, FaExclamationTriangle } from 'react-icons/fa';
import { z } from 'zod';

import {
  KepegawaianService,
  LandingPageService,
  WilayahService,
  model_RegistrasiSekolahRequest,
} from '@/api';
import { AlertBody, AlertHeader, AlertModal } from '@/components/alerts/Alert';
import {
  PasswordInput,
  SelectOption,
  SingleSelect,
  TextInput,
  TextareaInput,
} from '@/components/inputs';

import { useNavigate } from 'react-router-dom';
import Footer from '../components/footer';
import Navigation from '../components/navigation';

const validationSchema = z
  .object({
    username: z
      .string({
        required_error: 'Username wajib diisi',
      })
      .min(1, { message: 'Username tidak boleh kosong' })
      .max(50, { message: 'Username tidak boleh lebih dari 50 karakter' }),
    password: z
      .string({
        required_error: 'Password wajib diisi',
      })
      .min(8, { message: 'Password minimal 8 karakter' })
      .max(50, { message: 'Password tidak boleh lebih dari 50 karakter' }),
    password_confirmation: z
      .string({
        required_error: 'Konfirmasi Password wajib diisi',
      })
      .min(8, { message: 'Konfirmasi Password minimal 8 karakter' })
      .max(50, {
        message: 'Konfirmasi Password tidak boleh lebih dari 50 karakter',
      }),
    nama_sekolah: z
      .string({
        required_error: 'Nama Sekolah wajib diisi',
      })
      .min(1, { message: 'Nama Sekolah tidak boleh kosong' })
      .max(50, { message: 'Nama Sekolah tidak boleh lebih dari 50 karakter' }),
    jenis_sekolah: z.object({
      label: z.string(),
      value: z.number({ required_error: 'Jenis Sekolah wajib diisi' }),
    }),
    no_telepon: z
      .string({
        required_error: 'No Telepon wajib diisi',
      })
      .min(1, { message: 'No Telepon tidak boleh kosong' })
      .max(50, { message: 'No Telepon tidak boleh lebih dari 50 karakter' }),
    website: z
      .string()
      .max(50, { message: 'Website tidak boleh lebih dari 50 karakter' }),
    provinsi: z.object({
      label: z.string(),
      value: z.number({ required_error: 'Provinsi wajib diisi' }),
    }),
    kabupaten_kota: z.object({
      label: z.string(),
      value: z.number({ required_error: 'Kota wajib diisi' }),
    }),
    kecamatan: z.object({
      label: z.string(),
      value: z.number({ required_error: 'Kecamatan wajib diisi' }),
    }),
    kelurahan: z.object({
      label: z.string(),
      value: z.number({ required_error: 'Kelurahan wajib diisi' }),
    }),
    alamat: z
      .string({
        required_error: 'Alamat wajib diisi',
      })
      .min(1, { message: 'Alamat tidak boleh kosong' })
      .max(255, { message: 'Alamat tidak boleh lebih dari 255 karakter' }),
    email: z
      .string({
        required_error: 'Email wajib diisi',
      })
      .min(1, { message: 'Email tidak boleh kosong' })
      .max(50, { message: 'Email tidak boleh lebih dari 50 karakter' }),
    kode_pos: z
      .string({
        required_error: 'Kode Pos wajib diisi',
      })
      .min(1, { message: 'Kode Pos tidak boleh kosong' })
      .max(50, { message: 'Kode Pos tidak boleh lebih dari 50 karakter' }),
    nama_pemesan: z
      .string({
        required_error: 'Nama Pemesan wajib diisi',
      })
      .min(1, { message: 'Nama Pemesan tidak boleh kosong' })
      .max(50, { message: 'Nama Pemesan tidak boleh lebih dari 50 karakter' }),
    jabatan_pemesan: z.object({
      label: z.string(),
      value: z.number({ required_error: 'Jabatan wajib diisi' }),
    }),
    no_telepon_pemesan: z
      .string({
        required_error: 'No Telepon wajib diisi',
      })
      .min(1, { message: 'No Telepon tidak boleh kosong' })
      .max(50, { message: 'No Telepon tidak boleh lebih dari 50 karakter' }),
    email_pemesan: z
      .string({
        required_error: 'Email wajib diisi',
      })
      .min(1, { message: 'Email tidak boleh kosong' })
      .max(50, { message: 'Email tidak boleh lebih dari 50 karakter' }),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: 'Konfirmasi Password tidak sama',
    path: ['password_confirmation'],
  });

type ValidationSchema = z.infer<typeof validationSchema>;

type SelectOptionNumber = SelectOption<number>;

export default function RegistrasiSekolah() {
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
  });

  const toast = useToast();
  const popupSimpan = useDisclosure();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<ValidationSchema> = (data) => {
    if (data) {
      popupSimpan.onOpen();
    }
  };

  const registerMutation = useMutation((data: model_RegistrasiSekolahRequest) =>
    LandingPageService.createRegistrasiSekolah({ body: data })
  );

  const registerSekolah = () => {
    const data: model_RegistrasiSekolahRequest = {
      username: getValues().username,
      password: getValues().password,
      nama: getValues().nama_sekolah,
      jenis_sekolah: getValues().jenis_sekolah.value.toString(),
      telepon: getValues().no_telepon,
      website: getValues().website,
      provinsi: getValues().provinsi.value,
      kota: getValues().kabupaten_kota.value,
      kecamatan: getValues().kecamatan.value,
      kelurahan: getValues().kelurahan.value,
      alamat: getValues().alamat,
      email: getValues().email,
      kode_pos: getValues().kode_pos,
      nama_cp: getValues().nama_pemesan,
      jabatan_cp: getValues().jabatan_pemesan.value,
      telp_cp: getValues().no_telepon_pemesan,
      email_cp: getValues().email_pemesan,
    };

    registerMutation.mutate(data, {
      onSuccess: () => {
        popupSimpan.onClose();
        toast({
          title: 'Berhasil',
          description: 'Registrasi berhasil, silahkan cek email Anda',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate('/');
      },
      onError: (error) => {
        toast({
          title: 'Gagal',
          description:
            error instanceof Error ? error.message : 'Terjadi kesalahan',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    });
  };

  const { data: dataProvinsi } = useQuery(['provinsi'], () =>
    WilayahService.getProvinsi()
  );

  const { data: dataKota } = useQuery(
    ['kota', watch('provinsi')],
    () =>
      WilayahService.getKota({
        idProvinsi: watch('provinsi').value,
      }),
    {
      enabled: watch('provinsi') !== undefined,
      onSuccess: (data) => {
        const firstKota = {
          label: data[0].nama_kota,
          value: data[0].id_kota,
        };
        setValue('kabupaten_kota', firstKota);
      },
    }
  );

  const { data: dataKecamatan } = useQuery(
    ['kecamatan', watch('kabupaten_kota')],
    () =>
      WilayahService.getKecamatan({
        idKota: watch('kabupaten_kota').value,
      }),
    {
      enabled: watch('kabupaten_kota') !== undefined,
      onSuccess: (data) => {
        const firstKecamatan = {
          label: data[0].nama_kecamatan,
          value: data[0].id_kecamatan,
        };
        setValue('kecamatan', firstKecamatan);
      },
    }
  );

  const { data: dataKelurahan } = useQuery(
    ['kelurahan', watch('kecamatan')],
    () =>
      WilayahService.getKelurahan({
        idKecamatan: watch('kecamatan').value,
      }),
    {
      enabled: watch('kecamatan') !== undefined,
      onSuccess: (data) => {
        const firstKelurahan = {
          label: data[0].nama_kelurahan,
          value: data[0].id_kelurahan,
        };
        setValue('kelurahan', firstKelurahan);
      },
    }
  );

  const { data: dataPtk } = useQuery(['ptk'], () =>
    KepegawaianService.getJenisPtk()
  );

  return (
    <Box>
      <Navigation />
      <Box data-testid="content" p={8}>
        <Flex direction={'column'}>
          <Heading
            fontWeight="semibold"
            color="gray.800"
            fontSize={'5xl'}
            textAlign={'center'}
            py={12}
          >
            Registrasi Sekolah
          </Heading>
          <Flex
            px={8}
            pt={4}
            pb={6}
            direction={'column'}
            bg={'white'}
            rounded={'md'}
            my={4}
            shadow={'md'}
          >
            <Text fontWeight={'semibold'} fontSize={'2xl'}>
              Detail Akun
            </Text>
            <Grid
              as={'form'}
              alignItems={'center'}
              gap={2}
              templateColumns="repeat(1, 1fr 2fr)"
              pl={3}
              pt={3}
              w={'full'}
            >
              <GridItem>
                <FormLabel m={0}>Username*</FormLabel>
              </GridItem>
              <GridItem>
                <TextInput
                  autoComplete="username"
                  error={errors.username?.message}
                  {...register('username', { required: true })}
                />
              </GridItem>
              <GridItem>
                <FormLabel m={0}>Password*</FormLabel>
              </GridItem>
              <GridItem>
                <PasswordInput
                  autoComplete="new-password"
                  error={errors.password?.message}
                  {...register('password', { required: true })}
                />
              </GridItem>
              <GridItem>
                <FormLabel m={0}>Konfirmasi Password*</FormLabel>
              </GridItem>
              <GridItem>
                <PasswordInput
                  autoComplete="new-password"
                  error={errors.password_confirmation?.message}
                  {...register('password_confirmation', { required: true })}
                />
              </GridItem>
            </Grid>
          </Flex>
          <Flex
            px={8}
            pt={4}
            pb={6}
            direction={'column'}
            bg={'white'}
            rounded={'md'}
            my={4}
            shadow={'md'}
          >
            <Text fontWeight={'semibold'} fontSize={'2xl'}>
              Detail Sekolah
            </Text>
            <Grid
              alignItems={'center'}
              gap={2}
              templateColumns="repeat(1, 1fr 2fr)"
              pl={3}
              pt={3}
              w={'full'}
            >
              <GridItem>
                <FormLabel m={0}>Nama Sekolah*</FormLabel>
              </GridItem>
              <GridItem>
                <TextInput
                  error={errors.nama_sekolah?.message}
                  {...register('nama_sekolah', { required: true })}
                />
              </GridItem>
              <GridItem>
                <FormLabel m={0}>Jenis Sekolah*</FormLabel>
              </GridItem>
              <GridItem>
                <Controller
                  name="jenis_sekolah"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SingleSelect
                      error={errors.jenis_sekolah?.message}
                      onChange={onChange}
                      value={value}
                      options={
                        [
                          { label: 'SD', value: 1 },
                          { label: 'SMP', value: 2 },
                          { label: 'SMA', value: 3 },
                          { label: 'SMK', value: 4 },
                        ] as SelectOptionNumber[]
                      }
                    />
                  )}
                />
              </GridItem>
              <GridItem>
                <FormLabel m={0}>No Telepon*</FormLabel>
              </GridItem>
              <GridItem>
                <TextInput
                  error={errors.no_telepon?.message}
                  {...register('no_telepon', { required: true })}
                />
              </GridItem>
              <GridItem>
                <FormLabel m={0}>Website Sekolah</FormLabel>
              </GridItem>
              <GridItem>
                <TextInput
                  error={errors.website?.message}
                  {...register('website', { required: true })}
                />
              </GridItem>
              <GridItem>
                <FormLabel m={0}>Email Sekolah*</FormLabel>
              </GridItem>
              <GridItem>
                <TextInput
                  error={errors.email?.message}
                  {...register('email', { required: true })}
                />
              </GridItem>

              <GridItem>
                <FormLabel m={0}>Provinsi*</FormLabel>
              </GridItem>
              <GridItem>
                <Controller
                  name="provinsi"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SingleSelect
                      error={errors.provinsi?.message}
                      onChange={onChange}
                      value={value}
                      options={
                        dataProvinsi?.map((provinsi) => ({
                          label: provinsi.nama_provinsi,
                          value: provinsi.id_provinsi,
                        })) ?? []
                      }
                    />
                  )}
                />
              </GridItem>
              <GridItem>
                <FormLabel m={0}>Kabupaten/Kota*</FormLabel>
              </GridItem>
              <GridItem>
                <Controller
                  name="kabupaten_kota"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SingleSelect
                      error={errors.kabupaten_kota?.message}
                      onChange={onChange}
                      value={value}
                      options={
                        dataKota?.map((kota) => ({
                          label: kota.nama_kota,
                          value: kota.id_kota,
                        })) ?? []
                      }
                    />
                  )}
                />
              </GridItem>
              <GridItem>
                <FormLabel m={0}>Kecamatan*</FormLabel>
              </GridItem>
              <GridItem>
                <Controller
                  name="kecamatan"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SingleSelect
                      error={errors.kecamatan?.message}
                      onChange={onChange}
                      value={value}
                      options={
                        dataKecamatan?.map((kecamatan) => ({
                          label: kecamatan.nama_kecamatan,
                          value: kecamatan.id_kecamatan,
                        })) ?? []
                      }
                    />
                  )}
                />
              </GridItem>
              <GridItem>
                <FormLabel m={0}>Kelurahan*</FormLabel>
              </GridItem>
              <GridItem>
                <Controller
                  name="kelurahan"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SingleSelect
                      error={errors.kelurahan?.message}
                      onChange={onChange}
                      value={value}
                      options={
                        dataKelurahan?.map((kelurahan) => ({
                          label: kelurahan.nama_kelurahan,
                          value: kelurahan.id_kelurahan,
                        })) ?? []
                      }
                    />
                  )}
                />
              </GridItem>
              <GridItem alignSelf={'flex-start'} pt={2}>
                <FormLabel m={0}>Alamat*</FormLabel>
              </GridItem>
              <GridItem>
                <TextareaInput
                  error={errors.alamat?.message}
                  {...register('alamat', { required: true })}
                />
              </GridItem>
              <GridItem>
                <FormLabel m={0}>Kode Pos*</FormLabel>
              </GridItem>
              <GridItem>
                <TextInput
                  error={errors.kode_pos?.message}
                  {...register('kode_pos', { required: true })}
                />
              </GridItem>
            </Grid>
          </Flex>
          <Flex
            px={8}
            pt={4}
            pb={6}
            direction={'column'}
            bg={'white'}
            rounded={'md'}
            my={4}
            shadow={'md'}
          >
            <Text fontWeight={'semibold'} fontSize={'2xl'}>
              Detail Pemesan
            </Text>
            <Grid
              alignItems={'center'}
              gap={2}
              templateColumns="repeat(1, 1fr 2fr)"
              pl={3}
              pt={3}
              w={'full'}
            >
              <GridItem>
                <FormLabel m={0}>Nama Pemesan*</FormLabel>
              </GridItem>
              <GridItem>
                <TextInput
                  error={errors.nama_pemesan?.message}
                  {...register('nama_pemesan', { required: true })}
                />
              </GridItem>
              <GridItem>
                <FormLabel m={0}>Jabatan*</FormLabel>
              </GridItem>
              <GridItem>
                <Controller
                  name="jabatan_pemesan"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SingleSelect
                      error={errors.jabatan_pemesan?.message}
                      onChange={onChange}
                      value={value}
                      options={
                        dataPtk?.map((ptk) => ({
                          label: ptk.nama_ptk,
                          value: ptk.id_ptk,
                        })) ?? []
                      }
                    />
                  )}
                />
              </GridItem>
              <GridItem>
                <FormLabel m={0}>No Telepon*</FormLabel>
              </GridItem>
              <GridItem>
                <TextInput
                  error={errors.no_telepon_pemesan?.message}
                  {...register('no_telepon_pemesan', { required: true })}
                />
              </GridItem>
              <GridItem>
                <FormLabel m={0}>Email*</FormLabel>
              </GridItem>
              <GridItem>
                <TextInput
                  error={errors.email_pemesan?.message}
                  {...register('email_pemesan', { required: true })}
                />
              </GridItem>
            </Grid>
          </Flex>
        </Flex>
        <Flex justifyContent={'flex-end'} mt={4}>
          <Button
            variant={'erp-primary'}
            size={'lg'}
            onClick={handleSubmit(onSubmit)}
          >
            <Flex
              w={'full'}
              justifyContent={'space-between'}
              placeItems={'center'}
              gap={4}
              px={2}
            >
              Selanjutnya
              <FaArrowRight />
            </Flex>
          </Button>
        </Flex>
        <AlertModal
          disclosure={popupSimpan}
          isLoading={false}
          button={{
            label: 'Lanjutkan',
            onClick: () => {
              registerSekolah();
            },
          }}
          closeButtonText="Kembali"
        >
          <AlertHeader icon={FaExclamationTriangle} bg={'red.1000'}>
            Peringatan
          </AlertHeader>
          <AlertBody>
            Setelah ini, akun Anda akan diverifikasi. Proses tersebut
            membutuhkan waktu 1x24 jam. Silahkan cek email Anda untuk informasi
            lebih lanjut.
          </AlertBody>
        </AlertModal>
      </Box>
      <Footer />
    </Box>
  );
}
