import { Flex, Img, Text } from '@chakra-ui/react';

interface FiturProps {
  svgPath: string;
  title: string;
  description: string;
}

export default function Fitur({ svgPath, title, description }: FiturProps) {
  return (
    <Flex
      bg={'white'}
      rounded={'sm'}
      shadow={'dark-lg'}
      border={'2px solid #e2e8f0'}
      direction={'column'}
      borderRadius={'lg'}
      placeItems={'center'}
      maxW={'16rem'}
      minW={'16rem'}
      px={3}
      py={8}
    >
      <Img w={32} src={svgPath} alt={title} />
      <Text
        fontSize={'xl'}
        fontWeight={'semibold'}
        color={'blue.600'}
        my={4}
        px={4}
      >
        {title}
      </Text>
      <Text align={'center'}>{description}</Text>
    </Flex>
  );
}
