import { Box, Flex, Link, SimpleGrid } from '@chakra-ui/react';

export default function Footer() {
  return (
    <Box
      bg="#043277"
      color="gray.200"
      w={{
        base: 'full',
      }}
    >
      <Flex maxW={'6xl'} py={10} mx={'auto'}>
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '1fr 1fr 1fr 1fr 2fr' }}
          spacing={8}
        >
          <Flex gap={4} direction={'column'}>
            <Link href="#">Tentang</Link>
            <Link href={'#'}>Hubungi Kami</Link>
            <Link href={'#'}>Paket Penjualan</Link>
          </Flex>
        </SimpleGrid>
      </Flex>
    </Box>
  );
}
