import { RegistrasiService } from '@/api';
import { PasswordInput, TextInput } from '@/components/inputs';
import { useAuth } from '@/context/AuthContext';
import {
  Button,
  Center,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

const validationSchema = z
  .object({
    username: z
      .string({
        required_error: 'Username wajib diisi',
      })
      .min(1, { message: 'Username tidak boleh kosong' })
      .max(50, { message: 'Username tidak boleh lebih dari 50 karakter' }),
    password: z
      .string({
        required_error: 'Password wajib diisi',
      })
      .min(8, { message: 'Password minimal 8 karakter' })
      .max(50, { message: 'Password tidak boleh lebih dari 50 karakter' }),
    password_confirmation: z
      .string({
        required_error: 'Konfirmasi Password wajib diisi',
      })
      .min(8, { message: 'Konfirmasi Password minimal 8 karakter' })
      .max(50, {
        message: 'Konfirmasi Password tidak boleh lebih dari 50 karakter',
      }),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: 'Konfirmasi Password tidak sama',
    path: ['password_confirmation'],
  });
type ValidationSchema = z.infer<typeof validationSchema>;

export default function AktivasiUser() {
  const navigate = useNavigate();
  const { token } = useParams();
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
  });
  const { login } = useAuth();

  const activationMutation = useMutation(
    (data: { username: string; password: string }) =>
      RegistrasiService.activationUserAccount({
        payload: {
          username: data.username,
          password: data.password,
          user_activation_token: token ?? '',
        },
      }),
    {
      onSuccess: async () => {
        await login.mutateAsync({
          username: getValues('username'),
          password: getValues('password'),
        });
        navigate('/login');
      },
    }
  );

  const onSubmit: SubmitHandler<ValidationSchema> = (data) => {
    if (data && token) {
      activationMutation.mutate(data);
    }
  };

  return (
    <>
      <VStack h="100vh" bgGradient="linear(to-br, #80B2FF, #005FEE)">
        <Flex h="10" align="center" w="100%" p={2}>
          <Heading ml="4" size="md" fontWeight="bold" color="white">
            erp
          </Heading>
          <Heading ml="1" size="md" fontWeight="bold" color="blue.1100">
            uns
          </Heading>
        </Flex>
        <Center h="100vh">
          <Stack boxShadow="md" maxW="700" bg="white" p="14" rounded="2xl">
            <Heading
              textAlign="center"
              size="lg"
              fontWeight="bold"
              color="black"
            >
              Aktivasi Akun
            </Heading>
            <Text fontWeight={'semibold'} fontSize={'2xl'}>
              Detail Akun
            </Text>
            <Grid
              as={'form'}
              alignItems={'center'}
              gap={2}
              templateColumns="repeat(1, 1fr 2fr)"
              pl={3}
              pt={3}
              w={'full'}
            >
              <GridItem>
                <FormLabel fontSize="sm" fontWeight="semibold" color="gray.500">
                  Username*
                </FormLabel>
              </GridItem>
              <GridItem>
                <TextInput
                  rounded="full"
                  pr="4.5rem"
                  placeholder="Masukkan Username"
                  autoComplete="username"
                  error={errors.username?.message}
                  {...register('username', { required: true })}
                />
              </GridItem>
              <GridItem>
                <FormLabel fontSize="sm" fontWeight="semibold" color="gray.500">
                  Password*
                </FormLabel>
              </GridItem>
              <GridItem>
                <PasswordInput
                  rounded="full"
                  pr="4.5rem"
                  autoComplete="new-password"
                  error={errors.password?.message}
                  {...register('password', { required: true })}
                />
              </GridItem>
              <GridItem>
                <FormLabel fontSize="sm" fontWeight="semibold" color="gray.500">
                  Konfirmasi Password*
                </FormLabel>
              </GridItem>
              <GridItem>
                <PasswordInput
                  rounded="full"
                  pr="4.5rem"
                  autoComplete="new-password"
                  error={errors.password_confirmation?.message}
                  {...register('password_confirmation', { required: true })}
                />
              </GridItem>
              <GridItem>
                <FormLabel fontSize="sm" fontWeight="semibold" color="gray.500">
                  Token
                </FormLabel>
              </GridItem>
              <GridItem>
                <TextInput
                  rounded="full"
                  pr="4.5rem"
                  isDisabled
                  value={token}
                />
              </GridItem>
            </Grid>

            <Flex justifyContent={'flex-end'} mt={4}>
              <Button
                variant={'erp-primary'}
                size={'lg'}
                onClick={handleSubmit(onSubmit)}
              >
                <Flex
                  w={'full'}
                  justifyContent={'space-between'}
                  placeItems={'center'}
                  gap={4}
                  px={2}
                >
                  Aktivasi
                </Flex>
              </Button>
            </Flex>
          </Stack>
        </Center>
      </VStack>
    </>
  );
}
