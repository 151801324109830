import { Button, Flex, Link, Text } from '@chakra-ui/react';
import { FiCheck } from 'react-icons/fi';

import { model_PaketResponse } from '@/api';

interface PaketProps {
  paket: model_PaketResponse;
}

export default function Paket({ paket }: PaketProps) {
  return (
    <Flex
      bg={'gray.100'}
      rounded={'sm'}
      shadow={'md'}
      border={'2px solid #e2e8f0'}
      direction={'column'}
      gap={6}
      p={6}
      borderStyle={'solid'}
      borderWidth={'1px'}
      borderColor={'gray.500'}
      borderRadius={'xl'}
    >
      <Flex direction={'column'} gap={2} alignItems={'center'}>
        <Text fontSize={'xl'} fontWeight={'semibold'} textAlign={'center'}>
          {paket.nama_paket}
        </Text>
        <Text color={'gray.600'} my={'-2'} fontSize={'sm'}>
          {paket.deskripsi_paket}
        </Text>
      </Flex>
      <Flex
        direction={'column'}
        gap={2}
        placeItems={'center'}
        placeContent={'center'}
      >
        <Text fontSize={'4xl'} fontWeight={'semibold'}>
          {/* convert to rupiah */}
          {new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
          }).format(parseInt(paket.harga_perbulan))}
        </Text>
        <Text fontSize={'sm'} color={'gray.600'} my={-2}>
          user / bulan
        </Text>
      </Flex>

      <Link
        href={`paket/${paket.id_paket}`}
        variant={'no-underline'}
        mx={'auto'}
        my={3}
      >
        <Button variant={'erp-primary'}>
          <Text>Berlangganan</Text>
        </Button>
      </Link>

      <Flex direction={'column'}>
        {paket.fitur.slice(0, 6).map((f) => (
          <Flex gap={2} key={f.id_fitur}>
            <FiCheck color={'green.500'} />
            <Text fontSize={'sm'} color={'gray.600'}>
              {f.nama_fitur}
            </Text>
          </Flex>
        ))}
        {paket.fitur.length > 6 && (
          <Link alignSelf={'center'} href={`paket`} mt={4}>
            <Text fontSize={'sm'} color={'gray.600'}>
              Dan {paket.fitur.length - 6} fitur lainnya
            </Text>
          </Link>
        )}
      </Flex>
    </Flex>
  );
}
