import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { createContext, useContext } from 'react';

import {
  ApiError,
  AuthService,
  LayoutService,
  model_CurrentUserInfoResponse,
  model_LoginResponse,
} from '@/api';

import useLocalStorage from '../hooks/useLocalStorage';

interface UserData extends model_CurrentUserInfoResponse {
  loggedIn: boolean;
}

interface AuthContextProps {
  user: UserData;
  login: UseMutationResult<
    model_LoginResponse,
    unknown,
    {
      username: string;
      password: string;
    },
    unknown
  >;
  register: () => void;
  logout: () => void;
  getUser: UseQueryResult<model_CurrentUserInfoResponse, unknown>;
}

export const AuthContext = createContext<AuthContextProps | null>(null);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ ...props }: AuthProviderProps) => {
  const [user, setUser] = useLocalStorage('user-data') as [
    UserData,
    (value: UserData) => void
  ];
  const queryClient = useQueryClient();
  const login = useMutation(
    (data: { username: string; password: string }) =>
      AuthService.login({
        payload: {
          ...data,
        },
      }),
    {
      onSuccess: (data) => {
        Cookies.set('token', data.token);
      },
      onError: (error: ApiError) => {
        if (error.status === 401) {
          console.log('error');
        } else {
          console.log(error.message);
        }
      },
    }
  );

  const getUser = useQuery(
    ['current-user-info'],
    LayoutService.getCurrentUser,
    {
      retry: false,
      staleTime: Infinity,
      enabled: false,
      onSuccess: (data) => {
        console.log('data', data);
        setUser(
          Object.assign(data, {
            loggedIn: true,
          })
        );
        queryClient.invalidateQueries({});
      },
      onError: () => {
        setUser({
          role: [{ id_role: 0, nama_role: '' }],
          user: {
            username: '',
            id_ref_user: 0,
            id_sekolah: 0,
            id_user: 0,
            jenis_user: 0,
            status_aktif: 0,
            status_last_update: '',
          },
          user_detail: {
            id: 0,
            foto: '',
            nama: '',
          },
          loggedIn: false,
        });
        console.log('error');
      },
    }
  );

  const register = () => {
    console.log('register');
  };
  const logout = () => {
    const landingPage = Cookies.get('landing-page-sekolah');
    Cookies.remove('landing-page-sekolah');
    Cookies.remove('token');
    localStorage.removeItem('user-data');
    if (landingPage) {
      window.location.href = landingPage;
    } else {
      window.location.href = '/';
    }
  };

  return (
    <AuthContext.Provider
      value={{ register, logout, login, user, getUser }}
      {...props}
    />
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
