import {
  DropdownIndicatorProps,
  GroupBase,
  MultiValue,
  Props,
  SelectInstance,
  SingleValue,
  chakraComponents,
} from 'chakra-react-select';
import { RefAttributes } from 'react';
import { IoCaretDownOutline } from 'react-icons/io5';

export interface SelectOption<T> {
  label: string;
  value: T;
}

export function isSelectOption<T>(
  option: SelectOption<T> | GroupBase<SelectOption<T>>
): option is SelectOption<T> {
  return 'label' in option && 'value' in option;
}

export function isMultiValue<T>(
  value: MultiValue<SelectOption<T>> | SingleValue<SelectOption<T>>
): value is MultiValue<SelectOption<T>> {
  return Array.isArray(value);
}

export function isSingleValue<T>(
  value: MultiValue<SelectOption<T>> | SingleValue<SelectOption<T>>
): value is SingleValue<SelectOption<T>> {
  return !Array.isArray(value);
}

export type DefaultProps<T> = Props<
  SelectOption<T>,
  boolean,
  GroupBase<SelectOption<T>>
> &
  RefAttributes<
    SelectInstance<SelectOption<T>, boolean, GroupBase<SelectOption<T>>>
  >;

export function CustomDropdownIndicator<T>(
  dropdownProps: DropdownIndicatorProps<
    SelectOption<T>,
    true,
    GroupBase<SelectOption<T>>
  >
) {
  return (
    <chakraComponents.DropdownIndicator<
      SelectOption<T>,
      true,
      GroupBase<SelectOption<T>>
    >
      {...dropdownProps}
    >
      <IoCaretDownOutline />
    </chakraComponents.DropdownIndicator>
  );
}
