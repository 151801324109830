import './index.css';
import Provider from './provider';
import Router from './router';
export default function App() {
  return (
    <Provider>
      <Router />
    </Provider>
  );
}
