import { useQuery } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { createContext, useContext, useState } from 'react';

// import { useAuth } from './AuthContext';

import { LayoutService, model_FiturResponse } from '@/api';

import { useAuth } from './AuthContext';

interface MenuContextProps {
  fitur: model_FiturResponse[];
  mappedRoleWithFitur: model_FiturResponse[];
  getModuleFitur: (modulePath: string) => model_FiturResponse[];
}
export const MenuContext = createContext<MenuContextProps | null>(null);

interface MenuProviderProps {
  children: React.ReactNode;
}

export const MenuProvider = ({ children }: MenuProviderProps) => {
  const { user } = useAuth();
  const [fitur, setFitur] = useState<model_FiturResponse[]>([]);
  const [mappedRoleWithFitur, setMappedRoleWithFitur] = useState<
    model_FiturResponse[]
  >([]);

  useQuery(['current-user-fitur'], LayoutService.getCurrentUserFitur, {
    retry: false,
    enabled: !!user,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setFitur(data);
      setMappedRoleWithFitur(
        user?.role?.map((role) => {
          return {
            title: role.nama_role,
            id_role: role.id_role,
            module_path: `/${role.nama_role
              .toLowerCase()
              .replaceAll(' ', '-')}`,
            path: `/dashboard/${role.nama_role
              .toLowerCase()
              .replaceAll(' ', '-')}`,
            level: -1,
            parent: -1,
            is_menu: true,
            exact: false,
            children: data.filter((fitur) => fitur.id_role === role.id_role),
            slug_role: role.nama_role.toLowerCase().replaceAll(' ', '-'),
          };
        })
      );
    },
    onError: () => {
      console.log('error');
      localStorage.removeItem('user-data');
      Cookies.remove('token');
    },
  });

  const getModuleFitur = (modulePath: string) => {
    let children: model_FiturResponse[] = [];

    const flatFitur = fitur
      .map((fitur) => {
        const f = { ...fitur };
        if (f.children && f.children.length) {
          children = [...children, ...f.children];
        }
        return f;
      })
      .concat(children);
    return flatFitur.filter((f) => f.module_path === modulePath);
  };

  return (
    <MenuContext.Provider
      value={{ mappedRoleWithFitur, fitur, getModuleFitur }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const useMenu = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error('useMenu must be used within a MenuProvider');
  }
  return context;
};
