import {
  Input as ChakraInput,
  FormControl,
  FormControlProps,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import id from 'date-fns/locale/id';
import { Ref, forwardRef, useEffect, useRef, useState } from 'react';
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaClock } from 'react-icons/fa';
registerLocale('id', id);
type ReactDatePickerPropsWithoutOnChangeAndValue = Omit<
  ReactDatePickerProps,
  'onChange' | 'value'
>;

interface TimePickerProps extends ReactDatePickerPropsWithoutOnChangeAndValue {
  onChange?: (date: Date) => void;
  value: Date;
  formControl?: FormControlProps;
}

export function TimePicker({
  onChange,
  value,
  formControl,
  ...props
}: TimePickerProps) {
  return (
    <ReactDatePicker
      locale={id}
      onChange={(date: Date) => onChange && onChange(date)}
      showTimeSelect
      showTimeSelectOnly
      timeCaption="Waktu"
      dateFormat="HH:mm"
      selected={value}
      customInput={
        <Input
          setValue={onChange}
          isDisabled={props.disabled}
          isRequired={props.required}
          formControl={formControl}
        />
      }
      {...props}
    />
  );
}

interface InputTimeProps {
  isRequired?: boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
  onClick?: () => void | ((date: Date) => void);
  value?: string;
  setValue: ((date: Date) => void) | undefined;
  formControl?: FormControlProps;
}

const Input = forwardRef(
  (
    {
      isRequired,
      isInvalid,
      isDisabled,
      onClick,
      setValue,
      value,
      formControl,
    }: InputTimeProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const [text, setText] = useState(value ?? '');
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      setText(value ?? '');
    }, [value]);

    const updateValue = () => {
      // console.log('updateValue', text);
      // setValue && setValue(new Date(text));
      const inputValue = inputRef.current?.value;
      if (!inputValue) return;

      const currentDate = new Date();

      const [hour, minute] = inputValue.split(':');
      if (!hour || !minute) return;
      currentDate.setHours(parseInt(hour));
      currentDate.setMinutes(parseInt(minute));
      currentDate.setSeconds(0);
      setValue && setValue(currentDate);
    };

    useEffect(() => {
      // Add an event listener to the document to handle clicks outside the input
      const handleClickOutside = (event: MouseEvent) => {
        if (
          inputRef.current &&
          !inputRef.current.contains(event.target as Node)
        ) {
          // Clicked outside the input, set the text to the current input value
          updateValue();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const handleInputKeyPress = (
      event: React.KeyboardEvent<HTMLInputElement>
    ) => {
      if (event.key === 'Enter') {
        updateValue();
      }
    };

    return (
      <FormControl
        ref={ref}
        onClick={onClick}
        bg={'transparent'}
        isRequired={isRequired ?? false}
        isInvalid={isInvalid ?? false}
        isDisabled={isDisabled ?? false}
        minW={32}
        w={'full'}
        {...formControl}
      >
        <InputGroup placeContent={'space-between'}>
          <ChakraInput
            borderColor={
              isDisabled ? 'gray.300' : isInvalid ? 'red.500' : 'gray.300'
            }
            value={text}
            ref={inputRef}
            onChange={(e) => setText(e.target.value)}
            onKeyUp={handleInputKeyPress}
          ></ChakraInput>

          <InputRightElement
            pointerEvents="auto"
            borderLeftWidth="1px"
            borderLeftColor="gray.300"
            borderStyle="solid"
            color={isDisabled ? 'gray.300' : '#005FEE'}
            borderRightRadius="md"
            cursor={isDisabled ? 'not-allowed' : 'pointer'}
          >
            <FaClock />
          </InputRightElement>
        </InputGroup>
      </FormControl>
    );
  }
);

Input.displayName = 'Input';
