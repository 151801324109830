import {
  Box,
  Button,
  ChakraProvider,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { BsFillLockFill, BsFillPersonFill } from 'react-icons/bs';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '@/context/AuthContext';
import theme from '@/theme';
import axios from '@/utils/axios';

const decode = (word: string) => {
  return Buffer.from(word, 'base64').toString();
};

export default function Login() {
  const navigate = useNavigate();

  const [username, setUsername] = useState(localStorage.username || '');
  const [password, setPassword] = useState(
    localStorage.password ? decode(localStorage.password) : ''
  );
  const [accountInvalid, setAccountInvalid] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user, login, getUser } = useAuth();
  // const [show, setShow] = React.useState(false)

  const search = useLocation().search;
  const oauthtokens = new URLSearchParams(search).get('token');
  function inputFilled() {
    return password !== '' && username !== '' ? true : false;
  }

  const encode = (word: string) => {
    return Buffer.from(word).toString('base64');
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    await login.mutateAsync(
      {
        username: username,
        password: password,
      },
      {
        onSuccess: () => {
          setAccountInvalid(false);
          if (rememberMe) {
            localStorage.username = username;
            localStorage.password = encode(password);
          }
          getUser.refetch();
        },
        onError: () => {
          setAccountInvalid(true);
        },
      }
    ).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (oauthtokens) {
      axios
        .post('auth/oauth/token', {
          oauth_token: oauthtokens,
        })
        .then((response) => {
          setAccountInvalid(false);
          Cookies.set('token', response.data.token);
          if (Cookies.get('token')) {
            // const token = Cookies.get('token');
            getUser.refetch();
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setAccountInvalid(true);
          } else {
            console.log(error.response);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oauthtokens]);

  const [fetched, setFetched] = useState(false);
  if (!fetched) {
    getUser.refetch().then(() => {
      setFetched(true);
    });
  }
  if (user?.loggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <ChakraProvider theme={theme}>
      <Flex
        marginLeft={6}
        marginTop={3}
        position={'absolute'}
        zIndex={1}
        top={0}
        left={0}
        fontSize={{ base: '2xl', md: '4xl' }}
        fontWeight="semibold"
        maxW={{ base: 'full', md: '4xl' }}
      >
        <Text textShadow="2px 3px #808080" color="blue.600">
          E
        </Text>
        <Text textShadow="2px 3px #808080" color="red.600">
          D
        </Text>
        <Text textShadow="2px 3px #808080" color="orange.400">
          U
        </Text>
      </Flex>
      {/* <HStack ml="6" mt="3" pos="absolute" zIndex={1}>
        <Text as="b" fontSize="20" color="white">
          erp
        </Text>
        <Text as="b" fontSize="20" color="black">
          uns
        </Text>
      </HStack> */}
      <Image pos="absolute" src="Background.svg" h="100vh" objectFit="cover" />
      <Image
        pos="absolute"
        top="70"
        left="50"
        w="40wh"
        src="Illustrasi.svg"
        h="88vh"
        objectFit="contain"
      />
      <Flex direction="column" pos="absolute" right="150" top="100">
        <form onSubmit={handleSubmit}>
          <VStack maxW="360">
            <Text mb={2} as="b" fontSize="28" color="black">
              Masuk
            </Text>
            <FormControl isRequired isInvalid={accountInvalid}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={BsFillPersonFill} color="gray.400" />
                </InputLeftElement>
                <Input
                  borderColor="gray.300"
                  maxLength={100}
                  borderRadius="40px"
                  placeholder="Username"
                  // role="username"
                  data-testid="login-username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </InputGroup>
            </FormControl>

            <FormControl isRequired isInvalid={accountInvalid}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={BsFillLockFill} color="gray.400" />
                </InputLeftElement>
                <Input
                  borderColor="gray.300"
                  maxLength={100}
                  borderRadius="40px"
                  // type={show ? "text" : "password"}
                  type={'password'}
                  placeholder="Password"
                  // role="password"
                  data-testid="login-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {/* <InputRightElement 
                  onClick={() => setShow(!show)} 
                  children={ show ? <Image src="fa-solid_eye.svg" boxSize="4" color="gray.400" /> : <Image src="eye-slash-regular.svg" boxSize="4" color="gray.400" /> }
                /> */}
              </InputGroup>
              <FormErrorMessage marginX="5">
                {'Username/Password yang Anda masukkan salah! Coba lagi.'}
              </FormErrorMessage>
            </FormControl>
            <Container>
              <Checkbox
                isChecked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                mt={5}
                mb={2}
              >
                Ingat Saya
              </Checkbox>
              <Link
                onClick={() => navigate('/forgot-password')}
                mt={5}
                pos="absolute"
                right="5"
                color="#005FEE"
              >
                Lupa kata sandi?
              </Link>
            </Container>
            <Button
              borderRadius="40px"
              mt={10}
              color="white"
              bg={inputFilled() ? '#005FEE' : '#C1C1C1'}
              _hover={{ bg: inputFilled() ? '#005FEE' : '#C1C1C1' }}
              type="submit"
              w="360px"
              data-testid="login-submit"
              isLoading={isLoading}
            >
              Log In
            </Button>
            <Flex pt={4} pb={6} align="center" direction="row">
              <Box h="0.5px" w="90px" mr="10px" bg="blackAlpha.500"></Box>
              <Text color="blackAlpha.500" orientation="horizontal">
                atau&nbsp;
              </Text>
              <Text color="blackAlpha.500" orientation="horizontal">
                masuk&nbsp;
              </Text>
              <Text color="blackAlpha.500" orientation="horizontal">
                dengan
              </Text>
              <Box h="0.5px" w="90px" ml="10px" bg="blackAlpha.500"></Box>
            </Flex>
          </VStack>
        </form>
        <Button
          as="a"
          borderRadius="40px"
          color="#005FEE"
          colorScheme="white"
          leftIcon={<Image src="icons8-google.svg" boxSize="6" />}
          variant="outline"
          w="360px"
          href={`${import.meta.env.VITE_API_URL
            }/api/v1/auth/oauth/google/connect?redirectTo=${import.meta.env.VITE_APP_URL
            }/login`}
        >
          Google
        </Button>
      </Flex>
    </ChakraProvider>
  );
}
