import { Box, BoxProps, Text } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useState } from 'react';

import {
  CustomDropdownIndicator,
  DefaultProps,
  SelectOption,
  isSingleValue,
} from './util';

interface SingleSelectProps<T> extends DefaultProps<T> {
  onValueChange?: (value: SelectOption<T>) => void;
  defaultValue?: SelectOption<T> | null;
  error?: string;
  boxProps?: BoxProps;
}

export function SingleSelect<T>({
  onValueChange,
  defaultValue,
  error,
  boxProps,
  ...props
}: SingleSelectProps<T>): JSX.Element {
  const options = props.options;
  const firstOptions = defaultValue ?? (options?.at(0) as SelectOption<T>);
  const [value, setValue] = useState<SelectOption<T>>(firstOptions);

  return (
    <Box width="full" {...boxProps}>
      <Select
        placeholder="Pilih..."
        noOptionsMessage={() => 'Tidak ada pilihan'}
        value={value}
        options={options}
        onChange={(value) => {
          if (isSingleValue(value) && value !== null) {
            setValue(value);
            onValueChange && onValueChange(value);
          }
        }}
        chakraStyles={{
          option: (prev) => ({
            ...prev,
            zIndex: 100,
            _hover: {
              color: 'white',
              backgroundColor: 'blue.1000',
            },
          }),
          control: (prev) => ({
            ...prev,
            borderColor: error ? 'red.500' : 'gray.300',
          }),
          indicatorSeparator: (prev, { selectProps: { menuIsOpen } }) => ({
            ...prev,
            borderLeftWidth: menuIsOpen ? '3px' : '1px',
            borderColor: menuIsOpen
              ? 'blue.1000'
              : error
              ? 'red.500'
              : 'gray.300',
          }),
          dropdownIndicator: (prev, { selectProps: { menuIsOpen } }) => ({
            ...prev,
            width: '40px',
            margin: '0px',
            padding: '0px',
            textColor: menuIsOpen
              ? 'blue.1000'
              : error
              ? 'red.500'
              : 'gray.500',
            backgroundColor: 'transparent',
            '> svg': {
              transitionDuration: 'normal',
              transform: `rotate(${menuIsOpen ? -180 : 0}deg)`,
            },
          }),
        }}
        components={{
          DropdownIndicator: CustomDropdownIndicator,
        }}
        {...props}
      ></Select>
      <Text color={'red.500'} size={'sm'}>
        {error}
      </Text>
    </Box>
  );
}
