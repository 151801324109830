'use client';
import {
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import { ForwardedRef, forwardRef, useState } from 'react';
import { HiEye, HiEyeOff, HiLockClosed } from 'react-icons/hi';

import { TextInput } from './text-input';

interface PasswordInputProps extends InputProps {
  error?: string;
}

export const PasswordInput = forwardRef(
  (
    { error, ...props }: PasswordInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [show, setShow] = useState(false);

    return (
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <HiLockClosed color="gray.700" />
        </InputLeftElement>
        <TextInput
          error={error}
          ref={ref}
          px={9}
          type={show ? 'text' : 'password'}
          {...props}
        />
        <InputRightElement
          color={'gray.700'}
          pointerEvents="auto"
          rounded={'md'}
        >
          {show ? (
            <HiEyeOff onClick={() => setShow(!show)} cursor={'pointer'} />
          ) : (
            <HiEye onClick={() => setShow(!show)} cursor={'pointer'} />
          )}
        </InputRightElement>
      </InputGroup>
    );
  }
);

PasswordInput.displayName = 'PasswordInput';
