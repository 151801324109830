import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Img,
  Link,
  Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { LandingPageService } from '@/api';

import Fitur from '../components/fitur';
import Footer from '../components/footer';
import Paket from '../components/home-paket';
import Navigation from '../components/navigation';

function Home() {
  const { data: pakets } = useQuery(['listFitur'], () =>
    LandingPageService.getPilihanPaket()
  );

  return (
    <Box>
      <Navigation />

      {/* hero */}
      <Box bg={'blue.1000'} py={24} pos={'relative'} zIndex={0}>
        <Flex
          zIndex={1}
          gap={8}
          maxW="100rem"
          placeContent={'space-between'}
          placeItems={'center'}
          mx="auto"
          px={24}
        >
          <Flex
            direction={'column'}
            gap={4}
            maxW="28rem"
            h={'20rem'}
            placeContent={'space-between'}
          >
            <Heading fontWeight="semibold" color="white" fontSize={'5xl'}>
              Satu Solusi Banyak Manfaat
            </Heading>
            <Text color="white" fontSize={'xl'}>
              Pilih apa yang dibutuhkan dan integrasikan sesuai apa yang
              diinginkan
            </Text>
            <Link href={'/paket'} _hover={{ textDecoration: 'none' }}>
              <Button variant="erp-secondary" size="lg" w={'12rem'}>
                Coba Sekarang
              </Button>
            </Link>
          </Flex>
          <Img src={'/landing/hero.svg'} alt="hero" />
        </Flex>
        <Flex pos={'absolute'} right={0} top={0} zIndex={-1} h={'full'}>
          <Img src={'/landing/rectangle-hero.svg'} alt="hero" />
          <Img src={'/landing/rectangle-hero.svg'} alt="hero" />
        </Flex>
      </Box>

      {/* fitur */}
      <Grid
        gap={8}
        templateColumns="repeat(auto-fit, minmax(16rem, 1fr))"
        placeItems={'center'}
        w={{ base: 'full', md: '90vw' }}
        mx={'auto'}
        px={24}
        py={24}
      >
        <Fitur
          svgPath="/landing/maintenance.svg"
          title="Auto Maintenance"
          description="Tidak perlu report memaintenance"
        />
        <Fitur
          svgPath="/landing/integrated.svg"
          title="All Integrated"
          description="Semua saling terhubung dan menjadi satu"
        />
        <Fitur
          svgPath="/landing/user.svg"
          title="Up to 1000+ User"
          description="Batasan dengan skala yang besar"
        />
        <Fitur
          svgPath="/landing/device.svg"
          title="All Platform"
          description="Bisa terhubung lewat berbagai macam perangkat"
        />
      </Grid>

      {/* paket */}
      <Flex
        bg={'#d1e3ff'}
        direction={'column'}
        placeItems={'center'}
        placeContent={'center'}
        py={24}
        px={24}
      >
        <Heading fontWeight="semibold" color="gray.800" fontSize={'5xl'}>
          Pilih Paket Yang Sesuai Dengan Kebutuhan
        </Heading>
        <Flex gap={12} mt={12} direction={'row'}>
          {/* max 3 pakets */}
          {pakets &&
            pakets
              .slice(0, 3)
              .map((paket) => <Paket key={paket.id_paket} paket={paket} />)}
        </Flex>

        <Link href={'/paket'} my={12}>
          <Text w={'12rem'} mx={'auto'} fontSize={'lg'}>
            Lihat Semua Paket
          </Text>
        </Link>
      </Flex>

      <Footer />
    </Box>
  );
}

export default Home;
